const config = {
    skyScraper: {
        products_per_minute: 15000, /** 250 products * 60 seconds */
        performance_data_per_minute: 600000, /** approximately 10k per second */
    },
    google: {
        scopes: {
            'merchant_center_scope': 'https:\/\/www.googleapis.com\/auth\/content',
            'performance_data_scope': 'https:\/\/www.googleapis.com\/auth\/adwords'
        }
    },
    intercom: {
        supplemental_feed_setup: 'https://helpcenter.producthero.com/en/articles/8307082-set-up-the-producthero-supplemental-source',
        retrieve_data_on_our_platform: 'https://helpcenter.producthero.com/en/articles/9881534-retrieve-data-on-the-platform',
        products_ai_guide: 'https://helpcenter.producthero.com/en/articles/9739725-products-ai-guide',
        pmax_insights_guide: 'https://intercom.help/producthero-9749630d96ee/en/articles/10028041-performance-max-insights'
    },
    wizards: {
        /**
         * @todo min en max roas en clicks moeten uit backend config file komen
         * @see config/wizards.php
         */
        labelizer: {
            target_roas: {
                min: 1,
                max: 2000,
            },
            target_clicks: {
                min: 1,
                max: 250,
            },
            date_range: {
                recommendation: 30,
            },
        },
        merchant: {
            mc_next_article: 'https://blog.producthero.com/news/how-to-set-up-the-supplemental-feed-in-google-merchant-center-next-what-else-is-new'
        }
    },
    self_service_crm: {
        no_access_card_link: 'https://helpcenter.producthero.com/en/articles/10126086-access-to-the-account-settings'
    },
    product: {
        title_length: {
            all: { min: 0, max: null },
            poor: { min: 0, max: 30 },
            average: { min: 31, max: 75 },
            great: { min: 76, max: 150 },
            too_long: { min: 151, max: null },
        },
        max_short_title_length: 65,
        performance_card: {
            allowed_dates: 90
        },
        price_monitoring_card: {
            last_number_days: 30,
            allowed_dates: 90,
        },
        product_card: {
            text_area_rows: 3,
            title_low_threshold: 30,
            short_title_low_threshold: 30,
            max_toasts: 1,
            toast_duration: 4000,
        },
        default_edited_product_filter: 0,
    },
    products: {
        attribute_pagination: {
            per_page: 100,
        },
    },
    products_ai: {
        limit: {
            options: [10, 25, 50, 100, 200],
            default: 25,
        },
        filters_limit: {
            product_category: 15
        }
    },
    productGroup: {
        max_product_groups: 10,
        max_segments: 5,
        max_segment_items: 100,
        labelizer_strategy: {
            input_min_length: 1,
            input_max_length: 4,
            slider_min: 1,
            slider_max: 250,
            doughnut_chart_size: 150,
            init_target_roas: 250,
            init_target_clicks: 150,
            init_date_range: 30,
            target_roas_multiplier: 0.75,
            date_range_warning_value: 30
        },
        label_performance: {
            selector_default_option: 2
        }
    },
    youtube: {
        merchantWizard: {
            instruction_video: 'https://www.youtube.com/watch?v=gTxg5qpQ9Rk',
        },
        productGroup: {
            instruction_video: 'https://www.youtube.com/watch?v=fB0UUHRPtTo',
        },
    },
    featureFlags: {
        login: {
            show_email_login_link: false,
        },
        dashboard: {
            alerts: false,
            title_score: true,
        }
    },
    numberFormats: {
        'nl-NL': {
            currency: {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: '.',
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: '.',
            },
            int: {
                decimal: ',',
                thousands: '.',
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: '.',
            },
        },
        'en-US': { // United States Dollar (USD)
            currency: {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'en-GB': { // British Pound (GBP)
            currency: {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'fr-CH': { // Swiss Franc (CHF)
            currency: {
                style: 'currency',
                currency: 'CHF',
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: '.',
                thousands: '\'',
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: '.',
                thousands: '\'',
            },
            int: {
                decimal: '.',
                thousands: '\'',
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                decimal: '.',
                thousands: '\'',
            },
        },
        'sv-SE': { // Swedish Krona (SEK)
            currency: {
                style: 'currency',
                currency: 'SEK',
                currencyDisplay: 'narrowSymbol',
                currencySign: 'kr',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: ' ',
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: ' ',
            },
            int: {
                decimal: ',',
                thousands: ' ',
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
                decimal: ',',
                thousands: ' ',
            },
        },
        'pl-PL': { // Polish Złoty (PLN)
            currency: {
                style: 'currency',
                currency: 'PLN',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'zł', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'hu-HU': { // Hungarian Forint (HUF)
            currency: {
                style: 'currency',
                currency: 'HUF',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'Ft', // Specify the custom symbol
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            decimal: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'da-DK': { // Danish Krone (DKK)
            currency: {
                style: 'currency',
                currency: 'DKK',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'kr.', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'nb-NO': { // Norwegian Krone (NOK)
            currency: {
                style: 'currency',
                currency: 'NOK',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'kr', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'de-CH': { // Swiss Franc (CHF)
            currency: {
                style: 'currency',
                currency: 'CHF',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'Fr.', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'cs-CZ': { // Czech Crown (CZK)
            currency: {
                style: 'currency',
                currency: 'CZK',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'Kč', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
        'ro-RO': { // Romanian Leu (RON)
            currency: {
                style: 'currency',
                currency: 'RON',
                currencyDisplay: 'narrowSymbol', // Use the currency symbol
                currencySign: 'lei', // Specify the custom symbol
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            decimal: {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            int: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            percent: {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
        },
    },
    emails: {
        support: 'support@producthero.com'
    }
};

export default config;
